<template>
  <footer class="sticky-footer">
    <div class="container my-auto">
      <div class="copyright text-center my-auto"></div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterLayout",
};
</script>
